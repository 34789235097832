<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card>
          <div>
            <b-badge
              class="mr-50"
              variant="light-success"
            >
              Total Mentors: {{ mentors.length }}
            </b-badge>
            <b-badge
              class="mr-50"
              variant="light-primary"
            >
              In-House: {{ mentors.filter((mentor) => mentor.status === 'accept').length }}
            </b-badge>

            <b-badge
              class="mr-50"
              variant="light-primary"
            >
              Network : {{ mentors.filter((mentor) => mentor.status !== 'accept').length }}
            </b-badge>
          </div>
          <div class="d-flex filters-box mt-1">
            <div class="fl-col-1">
              <span>Filters</span>
            </div>
            <div class="fl-col-2 d-flex justify-content-start">
              <b-navbar-nav class="nav align-items-start mr-50">
                <b-nav-item-dropdown
                  class="dropdown-user nomarker"
                  left
                  toggle-class="d-flex align-items-center dropdown-user-link"
                >
                  <template #button-content>
                    <b-button
                      :variant="filters[0].selected ? 'primary' : 'outline-primary'"
                      class="d-flex align-items-center py-50 px-1"
                      pill
                      @click="filters[0].open=!filters[0].open"
                    >
                      <span>{{ filters[0].title }}</span>
                      <div
                        v-if="filters[0].selected != null"
                        :class="`${filters[0].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                        style="border-radius:50px; font-weight: bolder;"
                      >
                        {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                      </div>
                      <div
                        v-else
                        class="text-white py-25"
                        style="border-radius:50px; font-weight: bolder;"
                      >.</div>
                      <feather-icon
                        :icon="filters[0].open?'ChevronUpIcon':'ChevronDownIcon'"
                        class="ml-50"
                      />
                    </b-button>
                  </template>

                  <div
                    v-if="filters[0].options"
                  >
                    <div
                      class="custom-search px-1 pt-1"
                      style="cursor: pointer;"
                      @click="filters[0].selected=null"
                    >
                      <h6 class="text-primary">
                        -- No Filter --
                      </h6>
                    </div>
                    <div
                      v-for="option in filters[0].options"
                      :key="option.id"
                      class="custom-search px-1 pt-50"
                    >
                      <b-form-group
                        class="mb-0"
                      >
                        <b-form-checkbox
                          :checked="filters[0].selected && filters[0].selected === option"
                          @change="selectFilter(0, 'Single', option)"
                        >
                          {{ option.title }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <b-navbar-nav class="nav align-items-start mr-50">
                <b-nav-item-dropdown
                  class="dropdown-user nomarker"
                  left
                  toggle-class="d-flex align-items-center dropdown-user-link"
                >
                  <template #button-content>
                    <b-button
                      :variant="filters[1].selected ? 'primary' : 'outline-primary'"
                      class="d-flex align-items-center py-50 px-1"
                      pill
                      @click="filters[1].open=!filters[1].open"
                    >
                      <span>{{ filters[1].title }}</span>
                      <div
                        v-if="filters[1].selected != null"
                        :class="`${filters[1].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                        style="border-radius:50px; font-weight: bolder;"
                      >
                        {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
                      </div>
                      <div
                        v-else
                        class="text-white py-25"
                        style="border-radius:50px; font-weight: bolder;"
                      >.</div>
                      <feather-icon
                        :icon="filters[1].open?'ChevronUpIcon':'ChevronDownIcon'"
                        class="ml-50"
                      />
                    </b-button>
                  </template>

                  <div
                    v-if="filters[1].options"
                  >
                    <div
                      class="custom-search px-1 pt-1"
                      style="cursor: pointer;"
                      @click="filters[1].selected=null"
                    >
                      <h6 class="text-primary">
                        -- No Filter --
                      </h6>
                    </div>
                    <div
                      v-for="option in filters[1].options"
                      :key="option.id"
                      class="custom-search px-1 pt-50"
                    >
                      <b-form-group
                        class="mb-0"
                      >
                        <b-form-checkbox
                          :checked="filters[1].selected && filters[1].selected === option"
                          @change="selectFilter(1, 'Single', option)"
                        >
                          {{ option.title }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                    <div
                      v-if="filters[1].selected && filters[1].selected.value === 'custom'"
                      class="d-flex justify-content-between px-1 mt-50"
                    >
                      <b-form-group
                        class="mb-0 mr-50"
                        label="Min"
                        label-size="sm"
                      >
                        <b-form-input
                          v-model="rangeSlider.min"
                          placeholder="Min"
                          type="number"
                          min="0"
                          size="sm"
                        />
                      </b-form-group>
                      <b-form-group
                        class="mb-0"
                        label="Max"
                        label-size="sm"
                      >
                        <b-form-input
                          v-model="rangeSlider.max"
                          placeholder="Max"
                          type="number"
                          max="120"
                          size="sm"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <b-navbar-nav class="nav align-items-start mr-50">
                <b-nav-item-dropdown
                  class="dropdown-user nomarker"
                  left
                  toggle-class="d-flex align-items-center dropdown-user-link"
                >
                  <template #button-content>
                    <b-button
                      :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                      class="d-flex align-items-center py-50 px-1"
                      pill
                      @click="filters[2].open=!filters[2].open"
                    >
                      <span>{{ filters[2].title }}</span>
                      <div
                        v-if="filters[2].selected != null"
                        :class="`${filters[2].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                        style="border-radius:50px; font-weight: bolder;"
                      >
                        {{ filters[2].selected.length ? filters[2].selected.length : '1' }}
                      </div>
                      <div
                        v-else
                        class="text-white py-25"
                        style="border-radius:50px; font-weight: bolder;"
                      >.</div>
                      <feather-icon
                        :icon="filters[2].open?'ChevronUpIcon':'ChevronDownIcon'"
                        class="ml-50"
                      />
                    </b-button>
                  </template>

                  <div
                    v-if="filters[2].options"
                    style="max-height:400px; overflow-y: scroll;"
                  >
                    <div
                      class="custom-search px-1 pt-1"
                      style="cursor: pointer;"
                      @click="filters[2].selected=null"
                    >
                      <h6 class="text-primary">
                        -- No Filter --
                      </h6>
                    </div>
                    <div
                      v-for="option in filters[2].options"
                      :key="option.id"
                      class="custom-search px-1 pt-50"
                    >
                      <b-form-group
                        class="mb-0"
                      >
                        <b-form-checkbox
                          :checked="filters[2].selected && filters[2].selected.indexOf(option) >= 0"
                          @change="selectFilter(2, 'Multiple', option)"
                        >
                          {{ option.title }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <b-navbar-nav class="nav align-items-start mr-50">
                <b-nav-item-dropdown
                  class="dropdown-user nomarker"
                  left
                  toggle-class="d-flex align-items-center dropdown-user-link"
                >
                  <template #button-content>
                    <b-button
                      :variant="filters[3].selected ? 'primary' : 'outline-primary'"
                      class="d-flex align-items-center py-50 px-1"
                      pill
                      @click="filters[3].open=!filters[3].open"
                    >
                      <span>{{ filters[3].title }}</span>
                      <div
                        v-if="filters[3].selected != null"
                        :class="`${filters[3].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                        style="border-radius:50px; font-weight: bolder;"
                      >
                        {{ filters[3].selected.length ? filters[3].selected.length : '1' }}
                      </div>
                      <div
                        v-else
                        class="text-white py-25"
                        style="border-radius:50px; font-weight: bolder;"
                      >.</div>
                      <feather-icon
                        :icon="filters[3].open?'ChevronUpIcon':'ChevronDownIcon'"
                        class="ml-50"
                      />
                    </b-button>
                  </template>

                  <div
                    v-if="filters[3].options"
                  >
                    <div
                      class="custom-search px-1 pt-1"
                      style="cursor: pointer;"
                      @click="filters[3].selected=null"
                    >
                      <h6 class="text-primary">
                        -- No Filter --
                      </h6>
                    </div>
                    <div
                      v-for="option in filters[3].options"
                      :key="option.id"
                      class="custom-search px-1 pt-50"
                    >
                      <b-form-group
                        class="mb-0"
                      >
                        <b-form-checkbox
                          :checked="filters[3].selected && filters[3].selected.indexOf(option) >= 0"
                          @change="selectFilter(3, 'Multiple', option)"
                        >
                          {{ option.title }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <!-- <div class="filter-holder">
              </div> -->
            </div>
            <div class="fl-col-3">
              <b-button
                variant="primary"
                size="sm"
                @click="openAdvanceModal()"
              >
                Advance
              </b-button>
            </div>
          </div>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start mt-2">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :to="{name: 'add-mentors'}"
                  class="d-inline-flex mr-1"
                  variant="outline-primary"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span class="align-middle text-nowrap">Invite Mentor</span>
                </b-button>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="mentors"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Button -->
              <span v-if="props.column.field === 'buttons'">
                <b-button
                  :to="{name: 'mentor-manager', params: {id: props.row.id}}"
                  size="sm"
                  variant="outline-primary"
                >Manage</b-button>
              </span>

              <!-- Column: Name -->
              <span v-if="props.column.field === 'full_name'">
                {{ props.formattedRow[props.column.field] }}
                <!--                linkedin-->

                <b-link
                  v-b-tooltip:hover="'View Details'"
                  class="ml-50"
                  size="sm"
                  variant="flat-primary"
                  @click="$showEntityModal(props.row.id)"
                >
                  <feather-icon icon="InfoIcon" />
                </b-link>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
        <b-modal
          id="filterModal"
          ok-only
          no-close-on-backdrop
          size="lg"
          title="Advance Filters"
        >
          <h5><strong>Filters</strong></h5>
          <b-row>
            <b-col
              md="4"
              style="border-right: solid 1px #ebe9f1"
              class="pr-0"
            >
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[0].selected === null ? 'py-75' : 'py-50'} ${filters[0].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(0)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[0].title }}</span>
                  <div
                    v-if="filters[0].selected != null"
                    :class="`${filters[0].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[1].selected === null ? 'py-75' : 'py-50'} ${filters[1].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(1)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[1].title }}</span>
                  <div
                    v-if="filters[1].selected != null"
                    :class="`${filters[1].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[2].selected === null ? 'py-75' : 'py-50'} ${filters[2].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(2)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[2].title }}</span>
                  <div
                    v-if="filters[2].selected != null"
                    :class="`${filters[2].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[2].selected.length ? filters[2].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[3].selected === null ? 'py-75' : 'py-50'} ${filters[3].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(3)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[3].title }}</span>
                  <div
                    v-if="filters[3].selected != null"
                    :class="`${filters[3].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[3].selected.length ? filters[3].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[4].selected === null ? 'py-75' : 'py-50'} ${filters[4].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(4)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[4].title }}</span>
                  <div
                    v-if="filters[4].selected != null"
                    :class="`${filters[4].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[4].selected.length ? filters[4].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[5].selected === null ? 'py-75' : 'py-50'} ${filters[5].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(5)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[5].title }}</span>
                  <div
                    v-if="filters[5].selected != null"
                    :class="`${filters[5].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[5].selected.length ? filters[5].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
              <h6
                :class="`d-flex align-items-center mb-0 ${filters[6].selected === null ? 'py-75' : 'py-50'} ${filters[6].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
                style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
                @click="advanceSelector(6)"
              >
                <div class="d-flex align-items-center">
                  <span>{{ filters[6].title }}</span>
                  <div
                    v-if="filters[6].selected != null"
                    :class="`${filters[6].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[6].selected.length ? filters[6].selected.length : '1' }}
                  </div>
                </div>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </h6>
            </b-col>
            <b-col md="8">
              <div
                v-if="filters[0].options.length > 0"
                :style="`height: ${filters[0].open ? 'auto' : '1px' }; overflow: hidden;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[0].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[0].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[0].selected && filters[0].selected === option"
                      @change="selectFilter(0, 'Single', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="filters[1].options.length > 0"
                :style="`height: ${filters[1].open ? 'auto' : '1px' }; overflow: hidden;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[1].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[1].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[1].selected && filters[1].selected === option"
                      @change="selectFilter(1, 'Single', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="filters[2].options.length > 0"
                :style="`height: ${filters[2].open ? 'auto' : '1px' }; overflow: hidden; max-height:400px; overflow-y: scroll;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[2].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[2].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[2].selected && filters[2].selected.indexOf(option) >= 0"
                      @change="selectFilter(2, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="filters[3].options.length > 0"
                :style="`height: ${filters[3].open ? 'auto' : '1px' }; overflow: hidden;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[3].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[3].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[3].selected && filters[3].selected.indexOf(option) >= 0"
                      @change="selectFilter(3, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="filters[4].options.length > 0"
                :style="`height: ${filters[4].open ? 'auto' : '1px' }; overflow: hidden;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[4].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[4].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[4].selected && filters[4].selected.indexOf(option) >= 0"
                      @change="selectFilter(4, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="filters[5].options.length > 0"
                :style="`height: ${filters[5].open ? 'auto' : '1px' }; overflow: hidden;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[5].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[5].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[5].selected && filters[5].selected.indexOf(option) >= 0"
                      @change="selectFilter(5, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="filters[6].options.length > 0"
                :style="`height: ${filters[6].open ? 'auto' : '1px' }; overflow: hidden;`"
              >
                <div
                  class="custom-search px-1"
                  style="cursor: pointer;"
                  @click="filters[6].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[6].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[6].selected && filters[6].selected.indexOf(option) >= 0"
                      @change="selectFilter(6, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-modal>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BPagination, BRow, BBadge,
  BNavItemDropdown,
  BNavbarNav,
  BFormCheckbox, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import { getUserData } from '@/utils/auth'

export default {
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BRow,
    BBadge,
    VueGoodTable,
    BNavItemDropdown,
    BNavbarNav,
    BLink,
    BFormCheckbox,
  },
  data() {
    return {
      pageLength: 5,
      open: false,
      isFiltered: false,
      filters: [
        {
          open: false,
          selected: null,
          title: 'Type',
          options: [
            {
              id: 0,
              title: 'Inhouse',
              value: 'inhouse',
            },
            {
              id: 1,
              title: 'Network',
              value: 'network',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Associated Time',
          options: [
            {
              id: 0,
              title: '<6 months',
              value: '6',
            },
            {
              id: 1,
              title: '<=12 months',
              value: '12',
            },
            {
              id: 2,
              title: '<=18 months',
              value: '18',
            },
            {
              id: 3,
              title: '<=24 months',
              value: '24',
            },
            {
              id: 4,
              title: '<=36 months',
              value: '36',
            },
            {
              id: 5,
              title: '>36 months',
              value: '40',
            },
            {
              id: 6,
              title: 'Custom Range',
              value: 'custom',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Industry',
          options: [
            {
              id: 0,
              title: 'Agriculture and Allied Industries',
              value: 'Agriculture and Allied Industries',
            },
            {
              id: 1,
              title: 'Automobiles',
              value: 'Automobiles',
            },
            {
              id: 2,
              title: 'Auto Components',
              value: 'Auto Components',
            },
            {
              id: 3,
              title: 'Aviation',
              value: 'Aviation',
            },
            {
              id: 4,
              title: 'Banking',
              value: 'Banking',
            },
            {
              id: 5,
              title: 'Biotechnology',
              value: 'Biotechnology',
            },
            {
              id: 6,
              title: 'Cement',
              value: 'Cement',
            },
            {
              id: 7,
              title: 'Chemicals',
              value: 'Chemicals',
            },
            {
              id: 8,
              title: 'Consumer Durables',
              value: 'Consumer Durables',
            },
            {
              id: 9,
              title: 'Defence Manufacturing',
              value: 'Defence Manufacturing',
            },
            {
              id: 10,
              title: 'E-Commerce',
              value: 'E-Commerce',
            },
            {
              id: 11,
              title: 'Education and Training',
              value: 'Education and Training',
            },
            {
              id: 12,
              title: 'Electronics System Design and Manufacturing',
              value: 'Electronics System Design and Manufacturing',
            },
            {
              id: 13,
              title: 'Engineering and Capital Goods',
              value: 'Engineering and Capital Goods',
            },
            {
              id: 14,
              title: 'Financial Services',
              value: 'Financial Services',
            },
            {
              id: 15,
              title: 'FMCG',
              value: 'FMCG',
            },
            {
              id: 16,
              title: 'Gems and Jewellery',
              value: 'Gems and Jewellery',
            },
            {
              id: 17,
              title: 'Healthcare',
              value: 'Healthcare',
            },
            {
              id: 18,
              title: 'Infrastructure',
              value: 'Infrastructure',
            },
            {
              id: 19,
              title: 'Insurance',
              value: 'Insurance',
            },
            {
              id: 20,
              title: 'IT and BPM',
              value: 'IT and BPM',
            },
            {
              id: 21,
              title: 'Manufacturing',
              value: 'Manufacturing',
            },
            {
              id: 22,
              title: 'Media and Entertainment',
              value: 'Media and Entertainment',
            },
            {
              id: 23,
              title: 'Medical Devices',
              value: 'Medical Devices',
            },
            {
              id: 24,
              title: 'Metals and Mining',
              value: 'Metals and Mining',
            },
            {
              id: 25,
              title: 'MSME',
              value: 'MSME',
            },
            {
              id: 26,
              title: 'Oil and Gas',
              value: 'Oil and Gas',
            },
            {
              id: 27,
              title: 'Pharmaceuticals',
              value: 'Pharmaceuticals',
            },
            {
              id: 28,
              title: 'Ports',
              value: 'Ports',
            },
            {
              id: 29,
              title: 'Power',
              value: 'Power',
            },
            {
              id: 30,
              title: 'Railways',
              value: 'Railways',
            },
            {
              id: 31,
              title: 'Real Estate',
              value: 'Real Estate',
            },
            {
              id: 32,
              title: 'Renewable Energy',
              value: 'Renewable Energy',
            },
            {
              id: 33,
              title: 'Retail',
              value: 'Retail',
            },
            {
              id: 34,
              title: 'Roads',
              value: 'Roads',
            },
            {
              id: 35,
              title: 'Science and Technology',
              value: 'Science and Technology',
            },
            {
              id: 36,
              title: 'Services',
              value: 'Services',
            },
            {
              id: 37,
              title: 'Steel',
              value: 'Steel',
            },
            {
              id: 38,
              title: 'Telecommunications',
              value: 'Telecommunications',
            },
            {
              id: 39,
              title: 'Textiles',
              value: 'Textiles',
            },
            {
              id: 40,
              title: 'Tourism and Hospitality',
              value: 'Tourism and Hospitality',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Life-Cycle',
          options: [
            {
              id: 0,
              title: 'Ideation',
              value: 'Ideation',
            },
            {
              id: 0,
              title: 'Business Modelling',
              value: 'Business Modelling',
            },
            {
              id: 0,
              title: 'Prototyping',
              value: 'Prototyping',
            },
            {
              id: 0,
              title: 'MVP',
              value: 'MVP',
            },
            {
              id: 0,
              title: 'Market Traction',
              value: 'Market Traction',
            },
            {
              id: 0,
              title: 'Product Introduction',
              value: 'Product Introduction',
            },
            {
              id: 0,
              title: 'Revenue Generation',
              value: 'Revenue Generation',
            },
            {
              id: 0,
              title: 'Scaling',
              value: 'Scaling',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Offerings Type',
          options: [
            {
              id: 0,
              title: 'Product',
              value: 'Product',
            },
            {
              id: 1,
              title: 'Service',
              value: 'Service',
            },
            {
              id: 2,
              title: 'Hybrid',
              value: 'Hybrid',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Focus Area',
          options: [
            {
              id: 0,
              title: 'Profit',
              value: 'Profit',
            },
            {
              id: 1,
              title: 'Social Impact',
              value: 'Social Impact',
            },
            {
              id: 2,
              title: 'Environmental Impact',
              value: 'Environmental Impact',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Customer Type',
          options: [
            {
              id: 0,
              title: 'B2C',
              value: 'B2C',
            },
            {
              id: 1,
              title: 'B2B',
              value: 'B2B',
            },
            {
              id: 2,
              title: 'B2G',
              value: 'B2G',
            },
            {
              id: 3,
              title: 'D2C',
              value: 'D2C',
            },
            {
              id: 4,
              title: 'B2B2C',
              value: 'B2B2C',
            },
            {
              id: 5,
              title: 'B2B2B',
              value: 'B2B2B',
            },
            {
              id: 6,
              title: 'B2B2G',
              value: 'B2B2G',
            },
            {
              id: 7,
              title: 'B2G2C',
              value: 'B2G2C',
            },
            {
              id: 8,
              title: 'B2G2B',
              value: 'B2G2B',
            },
            {
              id: 9,
              title: 'B2C2C',
              value: 'B2C2C',
            },
            {
              id: 10,
              title: 'B2C2B',
              value: 'B2C2B',
            },
            {
              id: 11,
              title: 'B2C2G',
              value: 'B2C2G',
            },
          ],
        },
      ],
      rangeSlider: {
        min: 0,
        max: 120,
      },
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'full_name',
        },
        {
          label: 'Type',
          field(row) {
            if (row.status === 'accept') {
              return 'In-House'
            }
            return 'Network'
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for filter input
            filterDropdownItems: [
              {
                value: 'Onboarded',
                text: 'Onboarded',
              }, {
                value: 'Network',
                text: 'Network',
              }], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      mentors: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    advanceSelector(index) {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[index].open = true
    },
    openAdvanceModal() {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[0].open = true
      this.$bvModal.show('filterModal')
    },
    selectFilter(index, mode, value) {
      if (mode === 'Single') {
        if (this.filters[index].selected === value) {
          this.filters[index].selected = null
        } else {
          this.filters[index].selected = value
        }
      } else if (mode === 'Multiple') {
        if (this.filters[index].selected !== null && this.filters[index].selected.indexOf(value) >= 0) {
          const temp = this.filters[index].selected
          temp.splice(temp.indexOf(value), 1)
          this.filters[index].selected = temp
          if (temp.length === 0) {
            this.filters[index].selected = null
          }
        } else {
          const temp = this.filters[index].selected ? this.filters[index].selected : []
          temp.push(value)
          this.filters[index].selected = temp
        }
      }
    },
    getList(index) {
      let string = ''
      if (this.filters[index].selected) {
        this.filters[index].selected.map(list => {
          if (string === '') {
            string = `"${list.value}"`
          } else {
            string = `${string}, "${list.value}"`
          }
          return 0
        })
      }
      return string
    },
  },
  apollo: {
    mentors: {
      query() {
        return gql`
        query mentors {
          users_associationtable(
            where: {
              incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}},
              role: {_eq: "mentor"},
              ${this.filters[0].selected ? `
                status: {_is_null: ${this.filters[0].selected.value === 'network' ? 'true' : 'false'}},
              ` : ''}
              users_customuser: {
                ${this.filters[2].selected ? `
                  industry: {_in: [${this.getList(2)}]},
                ` : ''}
                ${this.filters[3].selected ? `
                  startup_stage: {_in: [${this.getList(3)}]},
                ` : ''}
                ${this.filters[4].selected ? `
                  offerings_type: {_in: [${this.getList(4)}]},
                ` : ''}
                ${this.filters[5].selected ? `
                  focus_type: {_in: [${this.getList(5)}]},
                ` : ''}
                ${this.filters[6].selected ? `
                  customer_type: {_in: [${this.getList(6)}]},
                ` : ''}
              },
              ${this.filters[1].selected ? `
                computed_total_associated_time: {
                  ${this.filters[1].selected.value !== 'custom' && this.filters[1].selected.value < 40 ? `
                    _lte: "${this.filters[1].selected.value}"
                  ` : ''}
                  ${this.filters[1].selected.value !== 'custom' && this.filters[1].selected.value === 40 ? `
                    _gt: "36"
                  ` : ''}
                  ${this.filters[1].selected.value === 'custom' ? `
                    _lte: "${this.rangeSlider.max}", _gt: "${this.rangeSlider.min}"
                  ` : ''}
                }
              ` : ''}
            }
          ) {
            id
            status
            users_customuser {
              full_name
              linkedin_profile
              phone_number
            }
          }
        }`
      },
      update(data) {
        const result = []
        data.users_associationtable.forEach(mentor => {
          result.push({
            id: mentor.id,
            status: mentor.status,
            ...mentor.users_customuser,
          })
        })
        return result
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
  .dropdown-toggle::after {
    display:none;
  }
  .filters-box{
    border-top: solid 1px #d3d3d3;
    border-bottom: solid 1px #d3d3d3;
  }
  .fl-col-1{
    width:fit-content;
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
  .fl-col-2{
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-left: solid 1px #d3d3d3;
    border-right: solid 1px #d3d3d3;
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: auto;
    overflow-y: visible; */
  }
  .fl-col-2::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .fl-col-2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .fl-col-2::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  .filter-holder{
    display: flex;
  }
  .fl-col-3{
    width:fit-content;
    padding-left: 1rem;
    display: flex;
    align-items: center;
  }
</style>
